<template>
  <div>
    <v-bottom-sheet :value="sheet" persistent>
      <v-sheet class="text-center" height="300">
        <v-btn class="mt-6" text color="primary" @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div class="my-3 d-flex justify-center flex-column align-center">
          <v-btn-toggle
            :value="inside"
            multiple
            @change="sync"
            active-class="primary lighten-5"
          >
            <v-btn value="binnen">
              <span style="text-transform: none">Binnen</span>
              <v-icon right> mdi-home</v-icon>
            </v-btn>
            <v-btn value="buiten">
              <span style="text-transform: none">Buiten</span>
              <v-icon right> mdi-tree</v-icon>
            </v-btn>
          </v-btn-toggle>

          <v-btn-toggle
            active-class="primary lighten-5"
            :value="services"
            @change="sync2"
            multiple
            class="d-flex flex-wrap justify-center"
          >
            <v-btn value="boeken">
              <span style="text-transform: none">Boeken</span>
              <v-icon right> mdi-book-open-page-variant</v-icon>
            </v-btn>
            <v-btn value="speelgoed">
              <span style="text-transform: none">Speelgoed</span>
              <v-icon right> mdi-teddy-bear</v-icon>
            </v-btn>
            <v-btn value="speeltoestel">
              <span style="text-transform: none">Speeltoestellen</span>
              <v-icon right> mdi-seesaw</v-icon>
            </v-btn>
            <v-btn value="dieren">
              <span style="text-transform: none">Dieren</span>
              <v-icon right> mdi-cow</v-icon>
            </v-btn>
            <v-btn value="eten & drinken">
              <span style="text-transform: none">Eten & drinken</span>
              <v-icon right> mdi-food</v-icon>
            </v-btn>
          </v-btn-toggle>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
export default {
  props: ["sheet", "inside", "services", "open"],
  methods: {
    sync(v) {
      this.$emit("update:inside", v);
      this.$emit("update");
    },
    sync2(v) {
      this.$emit("update:services", v);
      this.$emit("update");
    },
    sync3(v) {
      this.$emit("update:open", v);
      this.$emit("update");
    },
  },
};
</script>
