import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

export const google = {
  methods: {
    async google() {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();

      signInWithPopup(auth, provider).then(async function (result) {
        console.log(result);
      });
    },
  },
};
