<template>
  <div>
    <v-dialog :value="dialog">
      <v-card class="pa-4" v-if="dialog">
        <v-window v-model="step">
          <v-window-item :value="0" key="0">
            <div class="d-flex justify-space-between">
              <p style="font-size: 13px">Suggestie toevoegen</p>
              <v-btn @click="close" small icon
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </div>
            <div class="d-flex">
              <v-text-field
                hide-details=""
                solo
                class="my-1"
                flat
                background-color="grey lighten-4"
                label="Zoek naar een locatie"
                v-model="search"
                @input="searchResults"
              ></v-text-field>
            </div>
            <v-list v-if="results.length > 0">
              <div v-for="result in results" :key="result.place_id">
                <v-list-item
                  @click="select(result)"
                  style="
                    border-radius: 6px;
                    margin-bottom: 3px;
                    font-size: 13px;
                  "
                  class="grey lighten-4"
                  link
                  >{{ result.description }}</v-list-item
                >
              </div>
            </v-list>
          </v-window-item>

          <v-window-item :value="1" key="1">
            <div class="d-flex justify-space-between">
              <p style="font-size: 13px">Suggestie toevoegen</p>
              <v-btn @click="close" small icon
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </div>
            <div v-if="place">
              <v-text-field
                background-color="grey lighten-4"
                solo
                readonly
                flat
                :value="place.structured_formatting.main_text"
                hide-details=""
                class="mb-1"
              ></v-text-field>
              <v-textarea
                background-color="grey lighten-4"
                solo
                hint="Beschrijf voor andere ouders of bijv. er speelgoed aanwezig is. En ook voor welke leeftijden het leuk is."
                persistent-hint
                flat
                v-model="explanation"
                placeholder="Omschrijf de locatie zelf en waarom dit voor kinderen een leuke plek is?"
              ></v-textarea>

              <v-select
                class="mb-2"
                background-color="grey lighten-4"
                solo
                multiple
                v-model="inside"
                flat
                hide-details=""
                label="Binnen en/of buiten?"
                :items="['binnen', 'buiten']"
              ></v-select>
              <v-select
                background-color="grey lighten-4"
                solo
                multiple
                v-model="services"
                flat
                hide-details=""
                label="Voorzieningen (meerdere)"
                :items="[
                  'speelgoed',
                  'boeken',
                  'dieren',
                  'speeltoestel',
                  'eten & drinken',
                ]"
              ></v-select>

              <v-btn
                class="mt-2"
                color="primary"
                block
                style="text-transform: none"
                @click="addSuggestion"
                >Verzend suggestie</v-btn
              >
            </div>
          </v-window-item>

          <v-window-item :value="2" key="2">
            <div class="ma-2">
              <p style="font-size: 18px" class="font-weight-medium mb-1">
                Hartelijk dank voor uw suggestie!
              </p>
              <p>We zullen deze zo snel mogelijk toevoegen aan de lijst.</p>
              <v-btn
                @click="reset"
                elevation="0"
                block
                outlined
                class="mb-3"
                style="text-transform: none"
                >Doe nog een suggestie</v-btn
              >
              <v-btn
                block
                @click="close"
                elevation="0"
                style="text-transform: none"
                >Sluit</v-btn
              >
            </div>
          </v-window-item>
        </v-window>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { doc, setDoc } from "firebase/firestore";
import { db } from "@/firebase";

export default {
  props: ["dialog"],
  data() {
    return {
      inside: [],
      services: [],
      explanation: "",
      place: null,
      search: "",
      results: [],
      step: 0,
    };
  },

  methods: {
    async addSuggestion() {
      let data = {
        ...this.place,
        explanation: this.explanation,
        services: this.services,
        inside: this.inside,
      };
      await setDoc(doc(db, "suggestions", data.place_id), data);
      this.step = 2;
    },
    close() {
      this.place = null;
      this.results = [];
      this.search = "";
      this.explanation = "";
      this.inside = [];
      this.services = [];
      this.step = 0;
      this.$emit("close");
    },
    reset() {
      this.place = null;
      this.results = [];
      this.services = [];
      this.inside = [];
      this.search = "";
      this.explanation = "";
      this.step = 0;
    },
    searchResults() {
      const vm = this;
      const service = new window.google.maps.places.AutocompleteService();

      const displaySuggestions = function (predictions, status) {
        if (
          status != window.google.maps.places.PlacesServiceStatus.OK ||
          !predictions
        ) {
          return;
        }
        vm.results = predictions;
      };

      if (vm.search) {
        service.getPlacePredictions(
          {
            input: this.search,
            region: "nl",
            origin: new window.google.maps.LatLng(52.0931967, 5.1272871),
          },
          displaySuggestions
        );
      } else {
        vm.results = [];
      }
    },
    select(v) {
      console.log(v);
      this.place = v;
      this.step = 1;
    },

    sync(v) {
      this.$emit("update:dialog", v);
    },
  },
};
</script>

<style lang="scss" scoped></style>
