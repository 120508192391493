import Vue from "vue";
import App from "./App.vue";
import "./firebase";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./router";
import { getAuth, onAuthStateChanged } from "firebase/auth";
const auth = getAuth();

Vue.config.productionTip = false;
new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");

onAuthStateChanged(auth, (user) => {
  if (user) {
    store.dispatch("fetchUserProfile", user);
  }
});
