<template>
  <v-bottom-sheet :value="sheet" @input="close">
    <v-sheet
      class="text-center overflow-auto"
      :height="place && place.photos.length > 1 ? '70vh' : '400'"
    >
      <div
        class="d-flex px-6 pt-6 pb-2 justify-space-between"
        style="gap: 12px"
      >
        <v-btn text color="primary" @click="openMaps" icon>
          <v-icon>mdi-google-maps</v-icon>
        </v-btn>
        <v-btn text color="primary" icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="px-4 pb-4" v-if="place">
        <h3>{{ place.structured_formatting.main_text }}</h3>

        <p class="truncate">{{ place.explanation }}</p>
        <div class="d-flex justify-center" style="gap: 10px">
          <v-chip :color="place.open ? 'success' : 'error'">{{
            place.open
              ? `Open tot ${place.open_today.close.time}`
              : "Nu gesloten"
          }}</v-chip>

          <v-chip>
            <span
              v-if="
                place.inside.includes('buiten') &&
                !place.inside.includes('binnen')
              "
              >buiten</span
            >
            <span
              v-else-if="
                place.inside.includes('binnen') &&
                !place.inside.includes('buiten')
              "
              >binnen</span
            >
            <span v-else>binnen & buiten</span>
          </v-chip>

          <v-tooltip top v-if="place.services.includes('boeken')">
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-bind="attrs" v-on="on">
                <v-icon small>mdi-book-open-page-variant</v-icon></v-chip
              >
            </template>
            <span>Boeken</span>
          </v-tooltip>

          <v-tooltip top v-if="place.services.includes('speelgoed')">
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-bind="attrs" v-on="on">
                <v-icon small>mdi-teddy-bear</v-icon>
              </v-chip>
            </template>
            <span>Speelgoed</span>
          </v-tooltip>

          <v-tooltip top v-if="place.services.includes('dieren')">
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-bind="attrs" v-on="on">
                <v-icon small>mdi-cow</v-icon>
              </v-chip>
            </template>
            <span>Dieren</span>
          </v-tooltip>

          <v-tooltip top v-if="place.services.includes('speeltoestel')">
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-bind="attrs" v-on="on">
                <v-icon small>mdi-seesaw</v-icon>
              </v-chip>
            </template>
            <span>Speeltoestellen</span>
          </v-tooltip>

          <v-tooltip top v-if="place.services.includes('eten & drinken')">
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-bind="attrs" v-on="on">
                <v-icon small>mdi-food</v-icon>
              </v-chip>
            </template>
            <span>Eten & drinken</span>
          </v-tooltip>
        </div>
        <div class="images d-flex flex-column mt-6" style="gap: 10px">
          <v-img
            v-for="(image, index) in place.photos"
            :key="index"
            :src="`https://maps.googleapis.com/maps/api/place/photo
  ?maxwidth=400
  &photo_reference=${image.photo_reference}&key=AIzaSyBtf3wdzx0GyGq2bTjX6V5x7yzH-UnaH9c`"
          >
          </v-img>
        </div>
      </div>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
export default {
  props: ["sheet", "place"],
  data() {
    return {};
  },
  methods: {
    openMaps() {
      window.open(this.place.maps_url, "_blank");
    },
    sync(v) {
      this.$emit("update:sheet", v);
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
