import { db } from "@/firebase";
import { getDocs, collection } from "firebase/firestore";
// import { MarkerClusterer } from "@googlemaps/markerclusterer";
import moment from "moment";
import { getFunctions, httpsCallable } from "firebase/functions";

let map;
let infoWindow;

export const initialize = {
  data() {
    return {
      locations: [],
      markers: [],
      map: null,
      place: null,
    };
  },
  methods: {
    handleLocationError(browserHasGeolocation, infoWindow, pos) {
      infoWindow.setPosition(pos);
      infoWindow.setContent(
        browserHasGeolocation
          ? "Error: The Geolocation service failed."
          : "Error: Your browser doesn't support geolocation."
      );
    },
    currentLocation() {
      infoWindow = new window.google.maps.InfoWindow();
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };

            infoWindow.setPosition(pos);
            infoWindow.setContent("Location found.");

            map.setCenter(pos);
            map.setZoom(15);
          },
          () => {
            this.handleLocationError(true, infoWindow, map.getCenter());
          }
        );
      } else {
        // Browser doesn't support Geolocation
        this.handleLocationError(false, infoWindow, map.getCenter());
      }
    },
    addMarker(loc) {
      const marker = new window.google.maps.Marker({
        map,
        position: loc.location,
        animation: window.google.maps.Animation.DROP,
      });

      marker.addListener("click", () => {
        map.panTo(loc.position);
        this.openCard(loc);

        this.show_place = true;
      });

      this.markers.push(marker);
    },
    openCard(loc) {
      this.place = loc;
    },
    refreshMarkers() {
      for (let x in this.markers) {
        this.markers[x].setMap(null);
      }
      this.filtered.forEach((loc) => {
        this.addMarker(loc);
      });
    },
    initialize() {
      const vm = this;
      async function initMap() {
        map = new window.google.maps.Map(document.getElementById("map"), {
          center: { lat: 52.0931967, lng: 5.1272871 },
          zoom: 12,
          disableDefaultUI: true,
          gestureHandling: "greedy",
        });
        vm.currentLocation();

        const querySnapshot = await getDocs(collection(db, "locations"));
        for (let x in querySnapshot.docs) {
          let doc = querySnapshot.docs[x];
          const loc = doc.data();

          const functions = getFunctions();
          const getDetails = httpsCallable(functions, "getDetails");
          getDetails({ place_id: loc.place_id }).then((result) => {
            if (result.data.status === "OK") {
              let place = result.data.result;

              loc.location = place.geometry.location;
              loc.maps_url = `https://www.google.com/maps/search/?api=1&query=${loc.structured_formatting.secondary_text}&query_place_id=${loc.place_id}`;
              loc.position = {
                lat: place.geometry.location.lat,
                lng: place.geometry.location.lng,
              };
              loc.open = place.current_opening_hours
                ? place.current_opening_hours.open_now
                : false;
              loc.open_today = place.current_opening_hours
                ? place.current_opening_hours.periods.find(
                    (p) => moment().format("YYYY-MM-DD") === p.open.date
                  )
                : null;
              loc.photos = result.data.result.photos;
              console.log(result.data);

              loc.website = place.website ? place.website : null;
              const marker = new window.google.maps.Marker({
                map,
                position: loc.location,
                animation: window.google.maps.Animation.DROP,
              });

              marker.addListener("click", () => {
                map.panTo(loc.position);
                vm.openCard(loc);

                vm.show_place = true;
              });

              vm.markers.push(marker);
            } else {
              console.log(result.data.status + result.data.error_message);
            }
          });

          vm.locations.push(loc);
        }

        vm.loading = false;
      }

      window.initMap = initMap;
    },
  },
};
