<template>
  <div>
    <v-dialog
      :value="dialog"
      v-if="
        $store.state.userProfile &&
        $store.state.userProfile.email === 'maxvankaathoven@gmail.com'
      "
    >
      <v-card class="pa-4" v-if="dialog">
        <v-list v-if="suggestions.length > 0">
          <v-list-item
            v-for="suggestion in suggestions"
            :key="suggestion.place_id"
            class="grey lighten-4 py-2 mb-2"
          >
            <div
              style="
                display: grid;
                grid-template-columns: 4fr 40px;
                width: 100%;
              "
              class="py-2"
            >
              <div>
                <p class="font-weight-medium">
                  {{ suggestion.structured_formatting.main_text }}
                </p>
                <p class="mb-0">{{ suggestion.explanation }}</p>
                <div class="d-flex mt-3" style="gap: 10px">
                  <v-icon small v-if="suggestion.inside.includes('binnen')"
                    >mdi-home</v-icon
                  >
                  <v-icon small v-if="suggestion.inside.includes('buiten')"
                    >mdi-tree</v-icon
                  >
                  <v-icon small v-if="suggestion.services.includes('boeken')"
                    >mdi-book-open-page-variant</v-icon
                  >
                  <v-icon small v-if="suggestion.services.includes('speelgoed')"
                    >mdi-teddy-bear</v-icon
                  >
                  <v-icon small v-if="suggestion.services.includes('dieren')"
                    >mdi-cow</v-icon
                  >
                  <v-icon
                    small
                    v-if="suggestion.services.includes('speeltoestel')"
                    >mdi-see-saw</v-icon
                  >
                  <v-icon
                    small
                    v-if="suggestion.services.includes('eten & drinken')"
                    >mdi-food</v-icon
                  >
                </div>
              </div>
              <div class="d-flex flex-column justify-space-between">
                <v-btn @click="approve(suggestion)" icon color="primary"
                  ><v-icon>mdi-check-circle</v-icon></v-btn
                >
                <v-btn @click="openInMaps(suggestion)" icon
                  ><v-icon>mdi-google-maps</v-icon></v-btn
                >
              </div>
            </div>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { getDocs, collection } from "firebase/firestore";
import { doc, setDoc, deleteDoc } from "firebase/firestore";

export default {
  props: ["dialog"],
  data() {
    return {
      authenticated: false,

      suggestions: [],
    };
  },
  async mounted() {
    const querySnapshot = await getDocs(collection(db, "suggestions"));
    querySnapshot.forEach((doc) => {
      this.suggestions.push(doc.data());
    });
  },
  methods: {
    async approve(s) {
      await setDoc(doc(db, "locations", s.place_id), s);
      await deleteDoc(doc(db, "suggestions", s.place_id));
    },
    openInMaps(suggestion) {
      let url = `https://www.google.com/maps/search/?api=1&query=${suggestion.structured_formatting.secondary_text}&query_place_id=${suggestion.place_id}`;
      window.open(url, "_blank");
    },
    sync(v) {
      this.$emit("update:dialog", v);
    },
  },
};
</script>

<style lang="scss" scoped></style>
