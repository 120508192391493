import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDf6MOr_2UdWDBnCkvDzosRpgQ6GoDpDro",
  authDomain: "metjekids.firebaseapp.com",
  projectId: "metjekids",
  storageBucket: "metjekids.appspot.com",
  messagingSenderId: "506150971982",
  appId: "1:506150971982:web:5bcc6d84648f6aeaa2f11f",
};

const app = initializeApp(firebaseConfig);

// import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
// import { getApp } from "firebase/app";
// const functions = getFunctions(getApp());
// connectFunctionsEmulator(functions, "localhost", 5001);
const storage = getStorage(app);

// utils
const db = getFirestore(app);
const auth = getAuth;

// export utils/refs
export { db, auth, storage };
