<template>
  <v-app>
    <v-main id="main">
      <v-overlay :value="loading" opacity="0.8" style="z-index: 100">
        <v-img class="mb-2" width="50" src="@/assets/bear-icon.png"></v-img>
        <v-progress-linear
          indeterminate
          style="width: 50px"
          color="#00DEAB"
          rounded
        ></v-progress-linear>
      </v-overlay>
      <div class="container">
        <FilterSheet
          :inside.sync="inside"
          :services.sync="services"
          :sheet="show_filter"
          :open.sync="open"
          @close="show_filter = false"
          @update="refreshMarkers"
        />
        <AddPlace
          :dialog="show_addPlace"
          ref="approve"
          @close="show_addPlace = false"
        />
        <Approve :dialog="show_approve" @close="show_approve = false" />
        <PlaceCard
          :place="place"
          :sheet.sync="show_place"
          @close="
            show_place = false;
            place = null;
          "
        />
        <v-sheet
          style="
            position: absolute;
            top: 13px;
            left: 20px;
            z-index: 99;
            opacity: 0.8;
          "
          :rounded="true"
          class="pa-4"
        >
          <v-switch
            class="py-0 my-0"
            v-model="open"
            @change="refreshMarkers"
            hide-details=""
            label="Nu open"
            color="success"
            inset
          ></v-switch>
        </v-sheet>
        <v-speed-dial
          v-model="fab"
          top
          right
          absolute
          direction="bottom"
          transition="slide-y-transition"
        >
          <template v-slot:activator>
            <v-btn v-model="fab" color="blue darken-2" dark fab>
              <v-icon v-if="fab"> mdi-close </v-icon>
              <v-icon v-else> mdi-dots-vertical </v-icon>
            </v-btn>
          </template>
          <v-btn fab color="primary lighten-2" dark @click="show_filter = true">
            <v-icon>mdi-filter</v-icon>
          </v-btn>
          <v-btn
            v-if="
              $store.state.userProfile &&
              $store.state.userProfile.email === 'maxvankaathoven@gmail.com'
            "
            fab
            color="primary lighten-2"
            dark
            @click="show_approve = true"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>
          <v-btn
            @click="show_addPlace = true"
            fab
            color="primary lighten-2"
            dark
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn @click="currentLocation" fab color="primary lighten-2" dark
            ><v-icon>mdi-crosshairs-gps</v-icon></v-btn
          >
          <v-btn
            class="primary lighten-2"
            fab
            icon
            dark
            elevation="3"
            @click="google"
            v-if="superadmin"
            ><v-icon>mdi-google</v-icon></v-btn
          >
        </v-speed-dial>

        <div id="map"></div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import AddPlace from "@/components/addPlace.vue";
import Approve from "@/components/approveCard.vue";
import { initialize } from "@/mixins/initialize.js";
import { google } from "@/mixins/googleSignIn";
import FilterSheet from "@/components/filterSheet.vue";
import PlaceCard from "@/components/placeCard.vue";

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);
window.addEventListener("resize", () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
});

export default {
  name: "App",
  components: {
    AddPlace,
    Approve,
    FilterSheet,
    PlaceCard,
  },
  data() {
    return {
      admin: false,
      fab: false,
      open: false,
      inside: [],
      loading: true,
      services: [],
      show_filter: false,
      show_addPlace: false,
      show_approve: false,
      show_place: false,
    };
  },
  computed: {
    superadmin() {
      return !this.$store.state.userProfile && this.admin;
    },
    filtered() {
      let list = this.locations;

      if (this.inside.length > 0) {
        list = list.filter((loc) => {
          let ret = false;
          for (let x in this.inside) {
            if (loc.inside.includes(this.inside[x])) {
              ret = true;
            }
          }
          return ret;
        });
      }

      if (this.services.length > 0) {
        list = list.filter((loc) => {
          let ret = false;
          for (let x in this.services) {
            if (loc.services.includes(this.services[x])) {
              ret = true;
            }
          }
          return ret;
        });
      }

      if (this.open) {
        list = list.filter((loc) => loc.open);
      }

      return list;
    },
  },
  mixins: [initialize, google],
  async mounted() {
    this.initialize();
    this.admin = this.$route.query.admin;
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
html,
body {
  height: 100%;
  width: 100%;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  /* required to prevent rogue scrollbars */
  overflow: hidden;
  /* cosmetic stuff: */
  background-color: #5ae;
}
#main {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
  /* if you want the content to scroll normally: */
  overflow: hidden;
}
// #main {
//   height: 100vh;
//   height: calc(var(--vh, 1vh) * 100);
// }

.container {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  overflow: hidden;
}

#map {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  overflow: hidden;
}
</style>
